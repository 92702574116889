import { X } from 'lucide-react'
import { useMemo } from 'react'
import { Checkbox } from '~/components/ui/checkbox'
import type {
	AvailableTimeSlots,
	Slot,
} from '../../../_hooks/use-booking-simulation.hook'

export const RowCheckboxRenderer = ({
	row,
	selectedSlots,
	onCheck,
}: {
	row: AvailableTimeSlots
	selectedSlots: Slot[]
	onCheck: (isChecked: boolean) => void
}) => {
	const selectedChildren = useMemo(
		() => selectedSlots.filter(({ space_id }) => space_id === row.space.id),
		[selectedSlots, row.space.id],
	)

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const isFullyChecked = useMemo(() => {
		const hasSlots = !!selectedChildren.length

		const availabilities = row.availabilities?.filter(
			({ available }) => available,
		).length

		return hasSlots && availabilities === selectedChildren.length
	}, [selectedChildren, row.availabilities?.length])

	const cantCheck = useMemo(
		() => !row.availabilities?.filter((avail) => avail.available).length,
		[row.availabilities],
	)

	if (cantCheck) {
		return <X className='!stroke-[2px] mx-auto size-4 text-foreground/30' />
	}

	return (
		<Checkbox
			checked={isFullyChecked}
			onCheckedChange={onCheck}
		/>
	)
}
