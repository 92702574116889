import { createBookingWithEvent } from '@cocoonspace/sdk-js/domains/events/events'
import { getCleanObject } from '@cocoonspace/shared/utils/get-clean-object'
import { Users, X } from 'lucide-react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { ActionIcon } from '~/components/action-icon'
import { Button } from '~/components/ui/button'
import { Card } from '~/components/ui/card'
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '~/components/ui/table'
import { dialogsEmitter } from '~/emitters/dialogs.emitter'
import { useAdminApi } from '~/lib/sdk-admin-js'
import { useBookingSimulation } from '../../../_hooks/use-booking-simulation.hook'
import type { Slot } from '../../../_hooks/use-time-slots.hook'
import { genDateTimeUniqueKey } from '../../../_utils/gen-date-time-unique-key'
import { sortSlotsByStartDates } from '../../../_utils/sort-slots-by-start-dates'
import { quotationEmitter } from '../../../quotation.emitter'
import { DateTimeColumnHeader } from '../../atoms/date-time-column-header'
import { SpaceCellRenderer } from '../../atoms/space-cell-renderer'
import { DateTimeSlotPicker } from '../../molecules/date-time-slot-picker/date-time-slot-picker'
import { TableFilters } from '../../organisms/table-filters'
import { Recap } from './recap'
import { RowCheckboxRenderer } from './row-checkbox-renderer'
import { TimeslotCellRenderer } from './timeslot-cell-renderer'

export const TimeSlotsTable = ({
	isQuotationEditPage,
}: { isQuotationEditPage?: boolean }) => {
	const $adminApi = useAdminApi()
	const router = useRouter()
	const { t } = useTranslation()

	const [isLoading, setIsLoading] = useState(false)

	const [filters, setFilters] = useState({
		zone: 'paris',
		title: '',
		capacity: '',
		venues: [],
		features: [],
	})

	const {
		slotsState,
		dispatchSlots,
		spacesQuery,
		spacesWithAvail: availableTimeSlots,
	} = useBookingSimulation({
		spaceParams: getCleanObject(filters),
	})

	const addEvents = (id: string, availDataTimeSlots: Slot[]) => {
		const events = availDataTimeSlots.map((event) =>
			createBookingWithEvent({
				...event,
				day: event.day as string,
				start: event.start as string,
				end: event.end as string,
				booking: id,
				space: event.space_id as string,
			}),
		)

		return Promise.all(events).then(async () => {
			if (!isQuotationEditPage) {
				await router.push(`/quotations/${id}/edit`)
			}

			quotationEmitter.emit('quotation.addDateTimeSlots')
			dialogsEmitter.emit('availabilities.close')
		})
	}

	const addItemsToQuotation = () => {
		setIsLoading(true)

		if (isQuotationEditPage) {
			return addEvents(
				router.query.entryId as string,
				slotsState.selectedSlots,
			).finally(() => setIsLoading(false))
		}

		return $adminApi
			.createModuleEntry({
				uriParams: { describeId: 'quotations' },
				data: {},
			})
			.then((res) => addEvents(res.data.data.id, slotsState.selectedSlots))
			.finally(() => setIsLoading(false))
	}

	return (
		<>
			<div className='mb-4 flex flex-row items-end'>
				<div className='flex flex-row items-end gap-4'>
					<TableFilters
						value={filters}
						onChange={(newFilters) => setFilters(newFilters as any)}
					/>

					<DateTimeSlotPicker
						onChange={(timeslots) => {
							dispatchSlots({ type: 'add', payload: timeslots })
						}}
					/>
				</div>

				<div className='ml-auto flex flex-row items-center gap-6'>
					{!!slotsState.selectedSlots.length && (
						<Recap
							spaces={spacesQuery.data?.data}
							selectedSlots={slotsState.selectedSlots}
							onDelete={(payload) =>
								dispatchSlots({ type: 'unselect', payload })
							}
						/>
					)}

					<Button
						disabled={!slotsState.selectedSlots.length || isLoading}
						className='px-4'
						onClick={addItemsToQuotation}
					>
						<ActionIcon
							actionName='create'
							className='mr-2 text-sm'
						/>

						{isQuotationEditPage
							? t('actions:addToQuotation.btnLabel')
							: t('actions:createQuotation.btnLabel')}
					</Button>
				</div>
			</div>

			<Card className='flex flex-1 flex-col overflow-hidden'>
				<Table className='h-full border-separate'>
					<TableHeader className='sticky top-0 z-20'>
						<TableRow className='divide-x'>
							<TableHead className='sticky left-0 w-[40px] min-w-[40px] max-w-[40px] shrink-0 border-r border-b bg-card p-0 text-center' />

							<TableHead className='!border-l-0 z-20 w-[300px] min-w-[300px] max-w-[300px] border-b bg-card px-4'>
								Cocoon
							</TableHead>

							<TableHead className='!p-0 z-20 w-[40px] min-w-[40px] max-w-[40px] shrink-0 border-b bg-card px-4'>
								<Users className='mx-auto size-4' />
							</TableHead>

							{slotsState.slots
								.sort(sortSlotsByStartDates as any)
								.map((slot) => (
									<TableHead
										key={slot.day}
										className='w-[150px] border-b bg-card px-4 pr-2'
									>
										<DateTimeColumnHeader
											dateTimeSlot={slot as any}
											onDelete={() => {
												dispatchSlots({ type: 'remove', payload: slot })
											}}
										/>
									</TableHead>
								))}

							<TableHead className='border-b bg-card' />

							<TableHead className='sticky right-0 w-[100px] min-w-[100px] max-w-[100px] shrink-0 border-b bg-card'>
								Total
							</TableHead>
						</TableRow>
					</TableHeader>

					<TableBody className='flex-1 overflow-y-auto'>
						{availableTimeSlots.map((row) => (
							<TableRow
								key={row.space.id}
								className='group divide-x divide-y divide-dashed first:divide-y-0'
							>
								<TableCell className='sticky left-0 z-10 border-t border-r border-dashed bg-card p-0 text-center group-first:border-t-0'>
									<RowCheckboxRenderer
										row={row}
										selectedSlots={slotsState.selectedSlots}
										onCheck={(isChecked) => {
											if (isChecked) {
												dispatchSlots({
													type: 'select',
													payload: row.availabilities?.filter(
														(item) => item.available,
													) as any,
												})
											} else {
												dispatchSlots({
													type: 'unselect-row',
													payload: row.space.id,
												})
											}
										}}
									/>
								</TableCell>

								<TableCell className='!border-l-0'>
									<SpaceCellRenderer row={row} />
								</TableCell>

								<TableCell className='text-center'>
									{row.space.capacity}
								</TableCell>

								{slotsState.slots
									.sort(sortSlotsByStartDates as any)
									.map((slot) => (
										<TableCell
											key={slot.day}
											className='p-0 text-center'
										>
											<TimeslotCellRenderer
												value={
													row.availabilities?.find(
														(item: any) =>
															genDateTimeUniqueKey(item) ===
															genDateTimeUniqueKey(slot),
													) as any
												}
												selectedTimeslots={slotsState.selectedSlots}
												onCheck={(timeSlot, isSelected) =>
													isSelected
														? dispatchSlots({
																type: 'select',
																payload: [timeSlot],
															})
														: dispatchSlots({
																type: 'unselect',
																payload: timeSlot,
															})
												}
											/>
										</TableCell>
									))}

								<TableCell />

								<TableCell className='sticky right-0 z-10 bg-card text-center'>
									{row.countAvail === 0 ? (
										<X className='!stroke-[2px] mx-auto size-4 text-red-500' />
									) : (
										<div className='flex w-full flex-row items-end justify-end'>
											<div className='leading-none'>
												{t('common:currency.full', {
													val: row.totalPrice / 100,
												}).replace(',00', '')}
											</div>

											<div className='w-1/3 text-right text-slate-400 text-xs leading-none'>
												{row.countAvail}/{row.totalAvail}
											</div>
										</div>
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Card>
		</>
	)
}
